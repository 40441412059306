@import '../utils/base';

// Light theme colors
$lightPrimaryColor: #ffffff;
$lightPrimaryColorAlfa: rgba($lightPrimaryColor, .5);
$lightSecondaryColor: $lightColor;
$lightTextColor: #232323;
$lightBorderColor: rgb(0 0 0 / .125);
$lightTableBorderColor: $mediumGrey;
$lightActiveColor: $lightGrey;
$lightBrandColor: $mainColor;
$lightInputColor: $lightPrimaryColor;
$lightInputTextColor: #495057;
$lightDisabledInputColor: $lightColor;
$lightBorderInputColor: rgb(0 0 0 / .19);
$lightTableHighlightColor: rgb(0 0 0 / .075);

// Dark theme colors
$darkPrimaryColor: #161b22;
$darkPrimaryColorAlfa: rgba($darkPrimaryColor, .8);
$darkSecondaryColor: #0f131a;
$darkTextColor: rgb(201 209 217);
$darkBorderColor: rgb(255 255 255 / .15);
$darkTableBorderColor: #393d43;
$darkActiveColor: $darkSecondaryColor;
$darkBrandColor: #0b2d4e;
$darkInputColor: darken($darkPrimaryColor, 2%);
$darkInputTextColor: $darkTextColor;
$darkDisabledInputColor: lighten($darkPrimaryColor, 2%);
$darkBorderInputColor: $darkBorderColor;
$darkTableHighlightColor: $darkBorderColor;

html:not([data-theme='dark']) {
  --primary-color: #{$lightPrimaryColor};
  --primary-color-alfa: #{$lightPrimaryColorAlfa};
  --secondary-color: #{$lightSecondaryColor};
  --text-color: #{$lightTextColor};
  --border-color: #{$lightBorderColor};
  --active-color: #{$lightActiveColor};
  --brand-color: #{$lightBrandColor};
  --input-color: #{$lightInputColor};
  --input-disabled-color: #{$lightDisabledInputColor};
  --input-border-color: #{$lightBorderInputColor};
  --input-text-color: #{$lightInputTextColor};
  --table-border-color: #{$lightTableBorderColor};
  --table-highlight-color: #{$lightTableHighlightColor};
  --btn-close-filter: initial;
}

html[data-theme='dark'] {
  --primary-color: #{$darkPrimaryColor};
  --primary-color-alfa: #{$darkPrimaryColorAlfa};
  --secondary-color: #{$darkSecondaryColor};
  --text-color: #{$darkTextColor};
  --border-color: #{$darkBorderColor};
  --active-color: #{$darkActiveColor};
  --brand-color: #{$darkBrandColor};
  --input-color: #{$darkInputColor};
  --input-disabled-color: #{$darkDisabledInputColor};
  --input-border-color: #{$darkBorderInputColor};
  --input-text-color: #{$darkInputTextColor};
  --table-border-color: #{$darkTableBorderColor};
  --table-highlight-color: #{$darkTableHighlightColor};
  --btn-close-filter: invert(1);
}
